import React from "react"
import Box from "./box"
import PropTypes from "prop-types"

/**
 * Circles with a fixed size and numbers / icons inside. E.g. used as a counter for steps, etc.
 */
const Circle = ({ size, bg, children, color, ...restProps }) => {
  const sizes = {
    lg: {
      fontSize: 4,
      width: 12,
      height: 12,
    },
    md: {
      fontSize: 2,
      width: 8,
      height: 8,
    },
    sm: {
      fontSize: 1,
      width: 6,
      height: 6,
    },
    xs: {
      fontSize: "8px",
      width: 4,
      height: 4,
      fontWeight: "normal",
    },
    xxs: {
      fontSize: "8px",
      width: 2,
      height: 2,
      fontWeight: "normal",
    },
  }

  const sizeStyles = sizes[size]
  const sharedStyles = {
    alignItems: "center",
    bg,
    border: "1px solid transparent",
    color: color,
    borderRadius: "100%",
    display: "inline-flex",
    fontFamily: "sans",
    fontWeight: "bold",
    justifyContent: "center",
    textAlign: "center",
  }

  return (
    <Box
      sx={{
        ...sharedStyles,
        ...sizeStyles,
      }}
      {...restProps}
    >
      {children}
    </Box>
  )
}

Circle.displayName = "Circle"

Circle.propTypes = {
  /** Background Color */
  bg: PropTypes.string,
  /** Text Color */
  color: PropTypes.string,
  /** Circle Size */
  size: PropTypes.oneOf(["xxs", "xs", "sm", "md", "lg"]),
}

Circle.defaultProps = {
  bg: "border",
  size: "md",
  color: "text",
}

export default Circle
